import React from 'react'
import IconButton, { IconButtonKind } from './IconButton'
import LogoutIcon from './LogoutIcon'
import { useAuth } from '../graphql/hooks/useAuth'

const SignOutButton: React.FC = () => {
  const auth = useAuth()

  const handleLogout = () => {
    auth.signOut()
    //reset to all client state to initial state
    window.location.href = '/login'
  }

  return (
    <IconButton
      kind={IconButtonKind.custom}
      icon={<LogoutIcon />}
      additionalCss="focus:outline-none text-gray-700 hover:bg-gray-300 hover:text-blue-800 rounded p-1 active:bg-blue-400 active:text-white transition ease-in-out duration-150
"
      onClick={handleLogout}
    />
  )
}

export default SignOutButton
