import React, { FunctionComponent } from 'react'

export enum IconButtonKind {
  default = 'h-fit items-center px-0.5 py-0.5 rounded-md text-gray-700 hover:bg-blue-200 hover:text-blue-700 focus:border-gray-300 active:text-blue-800 focus:outline-none transition ease-in-out duration-150',
  custom = '',
}

export type IconButtonProps = {
  kind: IconButtonKind
  icon: React.ReactNode
  additionalCss?: string
  selected?: boolean
  selectedStyle?: string
  isExpandable?: boolean
  disabled?: boolean
  onClick?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const IconButton: FunctionComponent<IconButtonProps> = ({
  icon,
  kind = IconButtonKind.default,
  selected,
  onClick,
}) => {

  const defaultSelectedStyle = 'text-blue-600'
    return (
      <span className="inline-flex rounded-md">
        <button
          type="button"
          onClick={onClick}
          className={`${kind} ${selected ? defaultSelectedStyle : 'text-gray-700'}`}
        >
          {icon}
        </button>
      </span>
    )
}

export default IconButton
