import React from 'react'

const LogoutIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <polyline points="20 27 8 27 8 9 20 9 20 13" fill="none" stroke="#798596" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <polyline
      points="24.111 25.14 27.889 20 24.111 14.86"
      fill="none"
      stroke="#798596"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line x1="26.9707" y1="20" x2="15.3452" y2="20" fill="none" stroke="#798596" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </svg>
)

export default LogoutIcon
