import React from 'react'

const ScreenshotIcon: React.FC = () => (
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <path
      d="M22,24.6352v1.366A1.9988,1.9988,0,0,1,20.0012,28H9.9988A1.9988,1.9988,0,0,1,8,26.0012V15.9988A1.9988,1.9988,0,0,1,9.9988,14H20.0012A1.9988,1.9988,0,0,1,22,15.9988v.9506"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M14,10.2941v-.2908A2.0033,2.0033,0,0,1,16.0033,8h9.9934A2.0033,2.0033,0,0,1,28,10.0033v9.9934A2.0033,2.0033,0,0,1,25.9967,22h-.2908"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line x1="15.6219" y1="23" x2="22" y2="17" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <line
      x1="15.6262"
      y1="23"
      x2="11.9118"
      y2="19.5058"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default ScreenshotIcon
