import React, { useEffect, useState } from 'react'
import Map from './Map'
import SimpleGoogleMap from './SimpleGoogleMap'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import ClustersList from './ClustersList'
import InfoWindowContainer from './InfoWindowContainer'
import { useClustersQuery } from '../graphql/hooks/clusters'
import { TopPanel } from './TopPanel'
import { Pharmacy } from '../types/pharmacy'
import { ClusterHull } from '../types/cluster'
import { usePharmaciesQuery, usePharmaciesClustersHullsQuery } from '../graphql/hooks/pharmacies'
import { useClickToSelect, mergeRefs, Selection } from '../utils/utils'

/*
 * PROPS
 */
interface Props {
  setSelectedPharmacy: (id: string | undefined) => void
  selectedPharmacy: string | undefined
}
/*
 * COMPONENT
 */
const MapView: React.FC<Props> = ({
  setSelectedPharmacy,
  selectedPharmacy,
}) => {
  const { data: clusters } = useClustersQuery()
  const { data: pharmacies } = usePharmaciesQuery()
  const { data: hulls} = usePharmaciesClustersHullsQuery([])
  const [viewMode, setViewMode] = useState<'map' | 'pharmacy-pins'>('pharmacy-pins')
  const [pharmacyArray, setPharmacyArray] = useState<Pharmacy[]>([])
  const [hullsArray, setHullsArray] = useState<ClusterHull[]>([])
  const [showClusters, setShowClusters] = useState<string[]>([])
  const [isScreenshotEnabled, setIsScreenshotEnabled] = useState(false)
  const containerRef = React.useRef()
  const [clickToSelectRef, dx, dy, startX, startY, selection] = useClickToSelect()
  const containerMergedRef = mergeRefs([containerRef, clickToSelectRef])

  useEffect(() => {
    const filteredClusters = pharmacies?.filter(cluster => showClusters.includes(cluster.id))
    const filteredHulls = hulls?.filter(cluster => showClusters.includes(cluster.id)).filter(cluster => cluster !== undefined) as ClusterHull[]
    const pharmacy_array = filteredClusters
    ?.flatMap(cluster => cluster.pharmacies)
    .filter(pharmacy => pharmacy !== undefined) as Pharmacy[]

    setPharmacyArray(pharmacy_array)
    setHullsArray(filteredHulls)
  }, [pharmacies, showClusters, hulls])

  useEffect(() => {
    const clusterIds = clusters?.flatMap(item => item.id) || []
    setShowClusters(clusterIds)
  }, [clusters])
  
  const onCloseInfoWindow = () => {
    setSelectedPharmacy(undefined)
  }

  const changeColor = (id: string, color: string) => {
    const preparedPharmacies = pharmacyArray.map((pharmacy) => {
      if (pharmacy.id !== id) {
        return pharmacy
      } else {
        return {
          ...pharmacy,
          colorcode: color
        }
      }
    })
    setPharmacyArray(preparedPharmacies)
  }


  // React.useEffect(() => {
  //   if (!isScreenshotEnabled) {
  //     return
  // }

  //   const container = containerRef.current;
  //   if (!container || selection !== Selection.Selected || dx === 0 || dy === 0) {
  //       return
  //   }
  //   html2canvas(container, {
  //       x: startX,
  //       y: startY,
  //       width: dx,
  //       height: dy,
  //       useCORS: true,
  //   }).then((canvas: any) => {
  //       const img = canvas.toDataURL("image/png")

  //       const link = document.createElement("a")
  //       link.href = img
  //       link.download = "screenshot.png"

  //       document.body.appendChild(link)
  //       link.click()
  //       link.remove()
  //   })
  // }, [dx, dy, startX, startY, selection, isScreenshotEnabled])

  React.useEffect(() => {
    if (!isScreenshotEnabled) {
      return;
    }
  
    const container = containerRef.current;
    if (!container || selection !== Selection.Selected || dx === 0 || dy === 0) {
      return;
    }
  
    html2canvas(container, {
      x: startX,
      y: startY,
      width: dx,
      height: dy,
      useCORS: true,
    }).then((canvas: any) => {
      const imgData = canvas.toDataURL('image/png');
  
      // Create a new jsPDF instance
      const pdf = new jsPDF({
        orientation: dx > dy ? 'landscape' : 'portrait',
        unit: 'px',
        format: [dx, dy], // Set the PDF dimensions to match the selected area
      });
  
      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', 0, 0, dx, dy);
  
      // Save the PDF
      pdf.save('screenshot.pdf');
    });
  }, [dx, dy, startX, startY, selection, isScreenshotEnabled]);

  return (
    <div className="container-box" ref={containerMergedRef}>
                  {selection === Selection.Dragging && isScreenshotEnabled && (
                <div
                    className="selection"
                    style={{
                        transform: `translate(${startX}px, ${startY}px)`,
                        width: `${dx}px`,
                        height: `${dy}px`,
                    }}
                />
            )}
    <>
      {viewMode === "map" && (
        <SimpleGoogleMap hulls={hullsArray} isScreenshot={isScreenshotEnabled}>
          <Map 
            pharmacies={pharmacyArray} 
            onClick={setSelectedPharmacy}
            showPins={false}
          >
            <InfoWindowContainer
              onClose={onCloseInfoWindow}
              selectedPharmacy={selectedPharmacy}
              pharmacies={pharmacyArray}
              changeColor={changeColor}
            />
            <div className="fixed mt-0 mx-2 p-3 mt-2 right-0 w-1/6 bg-white rounded-md">
              <TopPanel 
                viewMode={viewMode}
                activateMapView={() => setViewMode("map")}
                activatePinsView={() => setViewMode("pharmacy-pins")}
                isScreenshot={isScreenshotEnabled}
                setIsScreenshot={setIsScreenshotEnabled}
              />
            </div>
            <div className="fixed bottom-0 m-2 mr-20 p-2 pr-12 left-0 w-auto overflow-x-auto h-144px flex justify-start bg-white rounded-md">
              <ClustersList 
                showClusters={showClusters}
                setShowClusters={setShowClusters}
              />
            </div>
          </Map>
        </SimpleGoogleMap>
      )}
      
      {viewMode === "pharmacy-pins" && (
        <SimpleGoogleMap isScreenshot={isScreenshotEnabled}>
        <Map 
          pharmacies={pharmacyArray} 
          onClick={setSelectedPharmacy}
          showPins={true}
        >
          <InfoWindowContainer
            onClose={onCloseInfoWindow}
            selectedPharmacy={selectedPharmacy}
            pharmacies={pharmacyArray}
            changeColor={changeColor}
          />
          <div className="fixed mt-0 mx-2 p-3 mt-2 right-0 w-1/6 bg-white rounded-md">
            <TopPanel 
              viewMode={viewMode}
              activateMapView={() => setViewMode("map")}
              activatePinsView={() => setViewMode("pharmacy-pins")}
              isScreenshot={isScreenshotEnabled}
              setIsScreenshot={setIsScreenshotEnabled}
            />
          </div>
          <div className="fixed bottom-0 m-2 mr-20 p-2 pr-12 left-0 w-auto overflow-x-auto h-144px flex justify-start bg-white rounded-md">
            <ClustersList 
              showClusters={showClusters}
              setShowClusters={setShowClusters}
            />
          </div>
        </Map>
      </SimpleGoogleMap>
      )}
    </>
    </div>
  )
  
}

export default MapView
