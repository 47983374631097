import React, { PropsWithChildren, useEffect, useRef } from 'react'
import { GoogleMap } from '@react-google-maps/api'
import { useMap } from '../contexts/mapContext'
import { ClusterHull } from '../types/cluster'

const defaultContainerStyle = {
  width: '100%',
  height: '100%',
};

const defaultCenter = {
  lat: 52.520008,
  lng: 13.404954,
};

interface ICustomGoogleMap {
  containerStyle?: {
    width: string
    height: string
  };
  center?: {
    lat: number
    lng: number
  };
  hulls?: ClusterHull[]
  isScreenshot: boolean
}

const SimpleGoogleMap: React.FC<PropsWithChildren<ICustomGoogleMap>> = ({
  children,
  containerStyle,
  center,
  hulls,
  isScreenshot
}) => {
  const { initializeMap, mapInstance } = useMap()
  const polygonsRef = useRef<google.maps.Polygon[]>([])

  useEffect(() => {
    polygonsRef.current.forEach((polygon) => polygon.setMap(null))
    polygonsRef.current = []

    if (hulls && mapInstance) {
      hulls.forEach((hull) => {
        const polygon = new google.maps.Polygon({
          paths: hull.hull_points,
          strokeColor: `#${hull.colorcode}`,
          strokeOpacity: 0.5,
          strokeWeight: 2,
          fillColor: `#${hull.colorcode}`,
          fillOpacity: 0.3,
        })

        polygon.setMap(mapInstance)
        polygonsRef.current.push(polygon)
      })
    }

    return () => {
      polygonsRef.current.forEach((polygon) => polygon.setMap(null))
      polygonsRef.current = []
    };
  }, [hulls, mapInstance])

  return (
    <GoogleMap
      center={center || defaultCenter}
      mapContainerStyle={containerStyle || defaultContainerStyle}
      zoom={8}
      onLoad={initializeMap}
      options={{
        mapTypeControl: false,
        fullscreenControl: false,
        gestureHandling: isScreenshot ? 'none' : 'auto',
      }}
    >
      {children}
    </GoogleMap>
  )
}

export default React.memo(SimpleGoogleMap)
