import { useQuery, useMutation, useQueryClient } from "react-query"
import { useGqlClient } from "../client"
import { useAuth } from "./useAuth"
import { pharmacies } from "../queries/pharmacies"
import { Pharmacy, PharmacyClusters } from "../../types/pharmacy"
import { pharmaciesByClusters, pharmaciesClusterHulls } from "../queries/pharmacies"
import { changeClusterId } from "../mutations/pharmacy"
import { ClusterHull } from "../../types/cluster"

type ChangeCluster = {
  id: string
  pharmacy_cluster_id: string
}

export const usePharmaciesQuery = (options?: any) => {
  const gqlClient = useGqlClient()
  const { token } = useAuth()
  return useQuery(
    'pharmacies', 
    async () => {
      const data = await gqlClient.request<{ pharmacy_clusters: PharmacyClusters[] }>(pharmacies)

      return data?.pharmacy_clusters || []
    },
    { enabled: !!token, staleTime: Infinity, ...options },
    )
  }

  export const usePharmaciesByClustersQuery = (clusterIds: string[], options?: any) => {
    const gqlClient = useGqlClient()
    const { token } = useAuth()
    return useQuery(
      ['pharmaciesByClusters', ...clusterIds], 
      async () => {
        const data = await gqlClient.request<{ pharmacies_by_cluster_ids: Pharmacy[] }>(
          pharmaciesByClusters,
          { clusterIds: [...clusterIds] }
        ) 
        return data?.pharmacies_by_cluster_ids || []
      },
      { enabled: !!token, staleTime: Infinity, ...options }
      )
  }

  export const usePharmaciesClustersHullsQuery = (pharmacy_cluster_ids: string[], options?: any) => {
    const gqlClient = useGqlClient()
    const { token } = useAuth()
    return useQuery(
      ['pharmaciesClusterHulls', ...pharmacy_cluster_ids], 
      async () => {
        const data = await gqlClient.request<{ pharmacy_cluster_hulls: ClusterHull[] }>(
          pharmaciesClusterHulls,
          { clusterIds: [...pharmacy_cluster_ids] }
        ) 
        return data?.pharmacy_cluster_hulls || []
      },
      { enabled: !!token, staleTime: Infinity, ...options }
      )
  }

  export const useChangeClusterId = () => {
    const gqlClient = useGqlClient()
    const queryClient = useQueryClient()
    const changePharmacyClusterId = async (variables: ChangeCluster) => await gqlClient.request(changeClusterId, variables)
    return useMutation( {
      mutationFn: changePharmacyClusterId,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['pharmaciesByClusters'] })
        queryClient.invalidateQueries({ queryKey: ['pharmacy_clusters']})
      },
      })
    }
