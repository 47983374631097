import SignOutButton from "./SignOutButton"
import logo from '../assets/Apo_Logo_Blau.svg'
import IconButton, { IconButtonKind } from "./IconButton"
import LocationMarkerInfoIcon from "../ui-components/LocationMarkerInfoIcon"
import MapIcon from "../ui-components/MapIcon"
import ScreenshotIcon from "../ui-components/ScreenshotIcon"

type Props = {
  viewMode: 'pharmacy-pins' | 'map'
	activatePinsView: () => void
  activateMapView: () => void
	isScreenshot: boolean
  setIsScreenshot: React.Dispatch<React.SetStateAction<boolean>>
}

export const TopPanel: React.FC<Props> = ({ viewMode, activatePinsView, activateMapView, isScreenshot, setIsScreenshot }) => {
	const iconButtonCss =
	'focus:outline-none text-gray-700 hover:bg-gray-300 hover:text-blue-800 rounded p-1 active:bg-blue-400 active:text-white transition ease-in-out duration-150'

  return (
		<div className="flex flex-row align-items-center justify-between">
			<div>
				<img src={logo} alt="Logo" className="h-16 w-auto px-2"/>
			</div>
			<IconButton
        kind={IconButtonKind.custom}
        selected={viewMode === 'pharmacy-pins'}
        icon={<LocationMarkerInfoIcon dimension={'36'} />}
        additionalCss={iconButtonCss}
        onClick={() => {
          if (viewMode !== 'pharmacy-pins') {
            activatePinsView()
          }
        }}
      />
      <div className="inline-flex border-l border-gray-400 border-solid w-px h-14 mx-0" />
      <IconButton
        kind={IconButtonKind.custom}
        selected={viewMode === 'map'}
        icon={<MapIcon dimension={'36'} />}
        additionalCss={iconButtonCss}
        onClick={() => {
          if (viewMode !== 'map') {
            activateMapView()
          }
        }}
      />
			<div className="inline-flex border-l border-gray-400 border-solid w-px h-14 mx-0" />
			<IconButton
        kind={IconButtonKind.custom}
        selected={isScreenshot}
        icon={<ScreenshotIcon />}
        additionalCss={iconButtonCss}
				onClick={() => setIsScreenshot(!isScreenshot)} 
				/>
			<div className="inline-flex border-l border-gray-400 border-solid w-px h-14 mx-0" />
			<SignOutButton />
		</div>
  )
}